@import "~react-datepicker/dist/react-datepicker.css";

code[class*=language-],
pre[class*=language-] {
    font-size: 15px;
    line-height: 120%;
}

.bg-code {
    background-color: #2d2a57 !important;
}

pre {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7) !important;
    z-index: 999;
}

.queryBuilder {
    .ruleGroup {
        background-color: transparent !important;
        border-color: rgba(0, 0, 0, 0.2) !important;

        .form-control,
        .form-select {
            display: inline-block;
            width: auto;
        }
    }
}

.masonry-grid {
    display: flex;
    margin-left: -30px;
    width: auto;
}

.masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

.masonry-grid_column>div {
    background: grey;
    margin-bottom: 30px;
}

.border-dashed {
    border-width: 1px;
}

.custom-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.custom-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

/* Style your items */
.custom-masonry-grid_column>div {
    margin-bottom: 30px;
}