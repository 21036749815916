@media print {
    body {
        margin: 0 !important;
    }
}

.main-container {
    --ckeditor5-preview-height: 700px;
    margin-left: auto;
    margin-right: auto;
}

.ck-content {
    line-height: 1.6;
    word-break: break-word;
}

.editor-container__editor-wrapper {
    display: flex;
}

.editor-container_document-editor {
    border-radius: 0.675rem;
    overflow: hidden;
    border: 1px solid var(--ck-color-base-border);
}

.editor-container_document-editor .editor-container__toolbar {
    display: flex;
    position: relative;
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
}

.editor-container_document-editor .editor-container__toolbar > .ck.ck-toolbar {
    flex-grow: 1;
    width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container__menu-bar {
    border-radius: 0.675rem;
}

.editor-container_document-editor .editor-container__menu-bar > .ck.ck-menu-bar {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.editor-container_document-editor .editor-container__editor-wrapper {
    max-height: var(--ckeditor5-preview-height);
    min-height: var(--ckeditor5-preview-height);
    overflow-y: scroll;
    background: var(--ck-color-base-foreground);
    border-radius: 0.675rem;
}

.editor-container_document-editor .editor-container__editor {
    width: 100%;
    height: 100%;
    margin-top: 28px;
    margin-bottom: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editor-container_document-editor .editor-container__editor .ck.ck-editor__editable {
    box-sizing: border-box;
    min-width: calc(210mm + 2px);
    max-width: calc(210mm + 2px);
    min-height: 297mm;
    height: fit-content;
    padding: 20mm 12mm;
    border: 1px hsl(0, 0%, 82.7%) solid;
    background: hsl(0, 0%, 100%);
    box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.078);
    flex: 1 1 auto;
}
