//
// Main
//

// Body
body {
	background-color: var(--#{$prefix}page-bg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;

	&.page-bg-image-lg {
		background-size: 100% get($page-bg-image-height-lg, desktop);
	}
}



// Font color from Content background color
.text-page-bg {
	color: $page-bg;
}

.page-bg {
	background-color: var(--#{$prefix}page-bg) !important;
}

.app-blank {
	background-color: var(--#{$prefix}app-blank-bg) !important;
}

// Nav custom
.nav-custom {
	.nav-link {
		color: $white !important;
		opacity: 0.7;

		&.active {
			opacity: 1;
			border-bottom: 2px solid $white !important;
		}

		&:hover {
			opacity: 1;
			border-bottom-color: transparent !important;
		}
	}
}

.css-t3ipsp-control,
.css-13cymwt-control,
.css-13cymwt-control:hover {
	min-height: 35px !important;
}

// Desktop Mode
@include media-breakpoint-up(lg) {

	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		padding: 0 get($content-spacing, desktop);
	}

	// Wrapper
	.wrapper {
		transition: padding-left get($aside-config, transition-speed) ease, margin-right get($aside-config, transition-speed) ease;

		// Fixed header mode
		.header-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, desktop, default, height);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {

	// Body
	body {
		background-size: 100% get($page-bg-image-height, tablet-and-mobile);

		&.page-bg-image-lg {
			background-size: 100% get($page-bg-image-height-lg, tablet-and-mobile);
		}
	}

	// Containers
	.container,
	.container-xxl,
	.container-fluid {
		max-width: none;
		padding: 0 get($content-spacing, tablet-and-mobile);
	}

	// Wrapper
	.wrapper {

		// Fixed header mode
		.header-tablet-and-mobile-fixed[data-kt-sticky-header="on"] & {
			padding-top: get($header-config, tablet-and-mobile, default, height);
		}
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.rct-checkbox {

	i.bi,
	i[class^=fonticon-],
	i[class*=" fonticon-"],
	i[class^=fa-],
	i[class*=" fa-"],
	i[class^=la-],
	i[class*=" la-"] {
		color: #000;
	}
}

.tree__item__label {
	border: 1px solid #ccc;
	padding: 7px 15px;
	display: inline-block;
	border-radius: $border-radius;
}

.downscaled-iframe {
	// -ms-transform: scale(0.75);
	// -moz-transform: scale(0.75);
	// -o-transform: scale(0.75);
	// -webkit-transform: scale(0.75);
	// transform: scale(0.75);

	// -ms-transform-origin: 0 0;
	// -moz-transform-origin: 0 0;
	// -o-transform-origin: 0 0;
	// -webkit-transform-origin: 0 0;
	// transform-origin: 0 0;	
}

.nav-link {
	i {
		color: inherit !important;
	}
}